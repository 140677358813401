import React from "react";
import {useSelector} from "react-redux";
import { Navigate, useLocation } from "react-router";

function PrivateRoute({children}) {
	
	const loggedIn = useSelector(store=>store.UserReducer.loggedIn);
	const status = useSelector(store=>store.UserReducer.data?.status);
	const migrated = useSelector(store=>store.UserReducer.data?.migrated);
	const location = useLocation();

	if(status === "pending" && !migrated){
		return <Navigate to="/verify_account"/>
	}else if(false && status === "lite" && location.pathname !== "/access"){ // Disable access view until we connect store
		return <Navigate to="/access"/>
	}else if(loggedIn){
		return children
	}else{
		return <Navigate to="/"/>
	}
}

export default PrivateRoute;